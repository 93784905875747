<template>
    <div class="d-flex flex-wrap flex-stack my-5">
        <h2 class="fs-2 fw-bold my-2">
            {{ $t('pages.ecommerce.orderManagement.changeUsageMulti.save.newChangeUsageMulti') }}
        </h2>

        <router-link v-if="!$root.filterWithUrl" to="/ecommerce/order-management/change-usage-multi" class="btn btn-primary align-self-center ms-4 back-page-btn">
            <span class="svg-icon svg-icon-3">
                <inline-svg src="/media/icons/duotune/arrows/arr021.svg" />
            </span>
            {{ $t('pages.ecommerce.orderManagement.changeUsageMulti.title') }}
        </router-link>
    </div>

    <el-form :model="form.data" ref="changeUsageMultiForm">
        <div class="row g-6 mb-6 g-xl-9 mb-xl-9">
            <div class="col-md-8">
                <div class="card">
                    <div class="card-body d-flex flex-column p-9">
                        <div class="row">
                            <div class="col-md-6 fv-row mb-1">
                                <label class="fs-6 fw-bold mb-2">
                                    {{ $t('pages.ecommerce.orderManagement.changeUsageMulti.save.cols.product') }}
                                    <el-tooltip v-if="form.selectedProduct.group && form.selectedProduct.group.code == 'PACKAGE'" class="item ms-1" popper-class="max-w-300px" effect="dark" :content="$t('pages.ecommerce.orderManagement.changeUsageMulti.save.informationBoxes.packageItem')" placement="top">
                                        <span class="svg-icon svg-icon-1">
                                            <inline-svg src="/media/icons/duotune/general/gen046.svg" />
                                        </span>
                                    </el-tooltip>
                                </label>
                                <el-form-item prop="product_id" :rules="$validation.getMessage(['required'])">
                                    <el-select v-model="form.data.product_id" class="w-100" :placeholder="$t('common.chooseSelect')" filterable @change="selectedProduct">
                                        <el-option v-for="(product, productIndex) in products" :key="productIndex" :value="product.id" :label="product.translate.title + ' (' + product.group.name + ')'"></el-option>
                                    </el-select>
                                </el-form-item>
                            </div>
                            <div class="col-md-6 fv-row mb-1">
                                <label class="fs-6 fw-bold mb-2">
                                    {{ $t('pages.ecommerce.orderManagement.changeUsageMulti.save.cols.order') }}
                                    <el-tooltip class="item ms-1" popper-class="max-w-300px" effect="dark" :content="$t('pages.ecommerce.orderManagement.changeUsageMulti.save.informationBoxes.order')" placement="top">
                                        <span class="svg-icon svg-icon-1">
                                            <inline-svg src="/media/icons/duotune/general/gen046.svg" />
                                        </span>
                                    </el-tooltip>
                                </label>
                                <el-form-item prop="order_id">
                                    <el-input v-model="form.data.order_id" type="text"/>
                                </el-form-item>
                            </div>
                        </div>
                    </div>
                </div>
                <template v-if="form.selectedProduct.group && form.selectedProduct.group.code == 'PACKAGE' && form.selectedProduct.package_items.length">
                    <div class="alert alert-danger d-flex align-items-center p-5 mt-9">
                        <span class="svg-icon svg-icon-2hx svg-icon-danger me-4">
                            <inline-svg src="/media/icons/duotune/general/gen048.svg" />
                        </span>
                        <div class="d-flex flex-column">
                            <h4 class="mb-1 text-danger" v-html="$t('pages.ecommerce.orderManagement.changeUsageMulti.save.alert.package_item.title')"></h4>
                            <span v-html="$t('pages.ecommerce.orderManagement.changeUsageMulti.save.alert.package_item.description')"></span>
                        </div>
                    </div>
                    <custom-table
                        :title="$t('pages.ecommerce.orderManagement.changeUsageMulti.save.packageItem.title')"
                        :subTitle="$t('pages.ecommerce.orderManagement.changeUsageMulti.save.packageItem.subTitle')"
                        class="mt-6"
                        selectableRows
                        :items="form.data.package_items"
                        :columns="packageItem.fields"
                        :actions="packageItem.actions"
                        @action="handleOptionClickAction"
                        @selectableRows="handleOptionSelectedRow">
                        <template v-slot:usageMinDate="{ row: record }">
                            {{ record.usage_min_date ? $moment(record.usage_min_date).format("DD.MM.YYYY - HH:mm:ss") : "-" }}
                        </template>
                        <template v-slot:usageMaxDate="{ row: record }">
                            {{ record.usage_max_date ? $moment(record.usage_max_date).format("DD.MM.YYYY - HH:mm:ss") : "-" }}
                        </template>
                        <template v-slot:justWithoutUsage="{ row: record }">
                            <span :class="record.just_without_usage ? 'badge-light-success': 'badge-light-danger'" class="badge">{{ record.just_without_usage ? $t('common.yes') : $t('common.no') }}</span>
                        </template>
                        <template v-slot:actions="{ row: record }">
                            <div class="d-flex justify-content-end">
                                <a v-on:click="editPackageItem(record)" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                                    <span class="svg-icon svg-icon-3">
                                        <inline-svg src="/media/icons/duotune/art/art005.svg" />
                                    </span>
                                </a>
                                <el-popconfirm :title="$t('messages.sureDelete')" :confirm-button-text="$t('btn.yes')" :cancel-button-text="$t('btn.no')" @confirm="deletePackageItemRecord([record])">
                                    <template #reference>
                                        <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                                        <span class="svg-icon svg-icon-3">
                                            <inline-svg src="/media/icons/duotune/general/gen027.svg"/>
                                        </span>
                                        </a>
                                    </template>
                                </el-popconfirm>
                            </div>
                        </template>
                    </custom-table>
                </template>
            </div>

            <div class="col-md-4">
                <div class="card">
                    <div class="card-body d-flex flex-column p-9">
                        <div class="fv-row mb-1">
                            <label class="fs-6 fw-bold mb-2">
                                {{ $t('pages.ecommerce.orderManagement.changeUsageMulti.save.cols.admin') }}
                                <el-tooltip class="item ms-1" popper-class="max-w-300px" effect="dark" :content="$t('pages.ecommerce.orderManagement.changeUsageMulti.save.informationBoxes.admin')" placement="top">
                                    <span class="svg-icon svg-icon-1">
                                        <inline-svg src="/media/icons/duotune/general/gen046.svg" />
                                    </span>
                                </el-tooltip>
                            </label>
                            <el-form-item prop="target_admin_id">
                                <el-select v-model="form.data.target_admin_id" class="w-100" :placeholder="$t('common.chooseSelect')" clearable>
                                    <el-option v-for="(admin, adminIndex) in admins" :key="adminIndex" :value="admin.id" :label="admin.firstname + ' ' + admin.lastname"></el-option>
                                </el-select>
                            </el-form-item>
                        </div>

                        <div class="fv-row mb-1">
                            <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.orderManagement.changeUsageMulti.save.cols.orderStatus') }}</label>
                            <el-form-item prop="target_order_statuses_id">
                                <el-select v-model="form.data.target_order_statuses_id" class="w-100" :placeholder="$t('common.chooseSelect')" multiple>
                                    <el-option v-for="(status, statusIndex) in orderStatus" :key="statusIndex" :value="status.id" :label="status.name">{{ status.name }}</el-option>
                                </el-select>
                            </el-form-item>
                        </div>

                        <div class="fv-row mb-1">
                            <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.orderManagement.changeUsageMulti.save.cols.usageMinDate') }}</label>
                            <el-form-item prop="usage_min_date">
                                <el-date-picker
                                    class="rangeDateTimePicker"
                                    v-model="form.data.usage_min_date"
                                    type="datetime"
                                    popper-class="rangeDateTimePickerPopper"
                                    :placeholder="$t('common.chooseDateAndTime')"
                                    valueFormat="YYYY-MM-DD HH:mm:ss"
                                    :disabledDate="disabledUsageMinDate"
                                >
                                </el-date-picker>
                            </el-form-item>
                        </div>

                        <div class="fv-row mb-1">
                            <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.orderManagement.changeUsageMulti.save.cols.usageMaxDate') }}</label>
                            <el-form-item prop="usage_max_date">
                                <el-date-picker
                                    class="rangeDateTimePicker"
                                    v-model="form.data.usage_max_date"
                                    type="datetime"
                                    popper-class="rangeDateTimePickerPopper"
                                    :placeholder="$t('common.chooseDateAndTime')"
                                    valueFormat="YYYY-MM-DD HH:mm:ss"
                                    :disabledDate="disabledUsageMaxDate"
                                >
                                </el-date-picker>
                            </el-form-item>
                        </div>

                        <div class="fv-row mb-1">
                            <label class="fs-6 fw-bold mb-2">
                                {{ $t('pages.ecommerce.orderManagement.changeUsageMulti.save.cols.justWithoutPackage') }}
                                <el-tooltip class="item ms-1" popper-class="max-w-300px" effect="dark" :content="$t('pages.ecommerce.orderManagement.changeUsageMulti.save.informationBoxes.justWithoutPackage')" placement="top">
                                    <span class="svg-icon svg-icon-1">
                                        <inline-svg src="/media/icons/duotune/general/gen046.svg" />
                                    </span>
                                </el-tooltip>
                            </label>
                            <el-form-item prop="just_without_package">
                                <el-radio-group v-model="form.data.just_without_package">
                                    <el-radio-button :label="true">{{ $t("common.yes") }}</el-radio-button>
                                    <el-radio-button :label="false">{{ $t("common.no") }}</el-radio-button>
                                </el-radio-group>
                            </el-form-item>
                        </div>

                        <div class="fv-row mb-1">
                            <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.orderManagement.changeUsageMulti.save.cols.justWithoutUsage') }}</label>
                            <el-form-item prop="just_without_usage">
                                <el-radio-group v-model="form.data.just_without_usage">
                                    <el-radio-button :label="true">{{ $t("common.yes") }}</el-radio-button>
                                    <el-radio-button :label="false">{{ $t("common.no") }}</el-radio-button>
                                </el-radio-group>
                            </el-form-item>
                        </div>

                        <div class="fv-row mb-1">
                            <label class="fs-6 fw-bold mb-2">
                                {{ $t('pages.ecommerce.orderManagement.changeUsageMulti.save.cols.startProcessingNow') }}
                                <el-tooltip class="item ms-1" popper-class="max-w-300px" effect="dark" :content="$t('pages.ecommerce.orderManagement.changeUsageMulti.save.informationBoxes.startProcessingNow')" placement="top">
                                    <span class="svg-icon svg-icon-1">
                                        <inline-svg src="/media/icons/duotune/general/gen046.svg" />
                                    </span>
                                </el-tooltip>
                            </label>
                            <el-form-item prop="start_processing_now">
                                <el-radio-group v-model="form.data.start_processing_now" @change="changedStartProcessingNow">
                                    <el-radio-button :label="true">{{ $t("common.yes") }}</el-radio-button>
                                    <el-radio-button :label="false">{{ $t("common.no") }}</el-radio-button>
                                </el-radio-group>
                            </el-form-item>
                        </div>

                        <div class="fv-row mt-5">
                            <el-form-item class="mb-0">
                                <button @click.prevent="onSubmit" :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-lg btn-primary w-100" type="button">
                                    <span v-if="!form.loading" class="indicator-label">{{ $t("btn.save") }}</span>
                                    <span v-if="form.loading" class="indicator-progress">
                                        {{ $t("messages.wait") }}
                                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                </button>
                            </el-form-item>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </el-form>

    <div class="modal fade" id="kt_modal_project_option" ref="packageItemModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
                <div class="modal-header">
                    <h2 class="fw-bolder">{{ packageItem.form.title }}</h2>
                    <div id="kt_modal_add_customer_close" data-bs-dismiss="modal" class="btn btn-icon btn-sm btn-active-icon-primary">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
                        </span>
                    </div>
                </div>
                <el-form @submit.prevent="onPackageItemSubmit()" :model="packageItem.form.data" ref="packageItemForm">
                    <div class="modal-body py-10 px-lg-17">
                        <div class="scroll-y me-n7 pe-7" id="kt_modal_add_customer_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.orderManagement.changeUsageMulti.save.cols.product') }}</label>
                                <el-form-item prop="product_id" :rules="$validation.getMessage(['required'])" v-if="!packageItem.form.updateStatus">
                                    <el-select v-model="packageItem.form.data.product_id" class="w-100" :placeholder="$t('common.chooseSelect')" filterable @change="selectedPackageItem">
                                        <el-option v-for="(packageItem, packageItemIndex) in addablePackageItems" :key="packageItemIndex" :value="packageItem.product_package_id" :label="packageItem.product.translate.title + ' (' + packageItem.product.group.name + ')'"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-input v-else v-model="packageItem.form.data.name" disabled></el-input>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.orderManagement.changeUsageMulti.save.packageItem.cols.usageMinDate') }}</label>
                                <el-form-item prop="usage_min_date">
                                    <el-date-picker
                                        class="rangeDateTimePicker"
                                        v-model="packageItem.form.data.usage_min_date"
                                        type="datetime"
                                        popper-class="rangeDateTimePickerPopper"
                                        :placeholder="$t('common.chooseDateAndTime')"
                                        valueFormat="YYYY-MM-DD HH:mm:ss"
                                        :disabledDate="disabledPackageItemUsageMinDate"
                                    >
                                    </el-date-picker>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.orderManagement.changeUsageMulti.save.packageItem.cols.usageMaxDate') }}</label>
                                <el-form-item prop="usage_max_date">
                                    <el-date-picker
                                        class="rangeDateTimePicker"
                                        v-model="packageItem.form.data.usage_max_date"
                                        type="datetime"
                                        popper-class="rangeDateTimePickerPopper"
                                        :placeholder="$t('common.chooseDateAndTime')"
                                        valueFormat="YYYY-MM-DD HH:mm:ss"
                                        :disabledDate="disabledPackageItemUsageMaxDate"
                                    >
                                    </el-date-picker>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.orderManagement.changeUsageMulti.save.cols.justWithoutUsage') }}</label>
                                <el-form-item prop="just_without_usage">
                                    <el-radio-group v-model="packageItem.form.data.just_without_usage">
                                        <el-radio-button :label="true">{{ $t("common.yes") }}</el-radio-button>
                                        <el-radio-button :label="false">{{ $t("common.no") }}</el-radio-button>
                                    </el-radio-group>
                                </el-form-item>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer flex-center">
                        <button :data-kt-indicator="packageItem.form.loading ? 'on' : null" class="btn btn-lg btn-primary" type="submit" :disabled="packageItem.form.loading">
                            <span v-if="!packageItem.form.loading" class="indicator-label">{{ $t("btn.save") }}</span>
                            <span v-if="packageItem.form.loading" class="indicator-progress">
                                {{  $t("messages.wait") }}
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
import CustomTable from "@/components/custom-table";

export default {
    name: "index",
    components: {
        CustomTable,
    },
    data() {
        return {
            tabModel: 'general',
            form: {
                updateStatus: false,
                loading: false,
                selectedProduct: {
                    package_items: []
                },
                data: {
                    package_items: [],
                    target_order_statuses_id: [],
                    just_without_package: true,
                    just_without_usage: false,
                    start_processing_now: false
                }
            },
            packageItem: {
                fields: [
                    {
                        name: this.$t("pages.ecommerce.orderManagement.changeUsageMulti.save.packageItem.cols.packageItem"),
                        key: "name"
                    },
                    {
                        name: this.$t("pages.ecommerce.orderManagement.changeUsageMulti.save.packageItem.cols.usageMinDate"),
                        scopedSlots: {customRender: "usageMinDate"}
                    },
                    {
                        name: this.$t("pages.ecommerce.orderManagement.changeUsageMulti.save.packageItem.cols.usageMaxDate"),
                        scopedSlots: {customRender: "usageMaxDate"}
                    },
                    {
                        name: this.$t("pages.ecommerce.orderManagement.changeUsageMulti.save.packageItem.cols.justWithoutUsage"),
                        scopedSlots: {customRender: "justWithoutUsage"}
                    },
                    {
                        name: this.$t("common.action"),
                        key: "action",
                        scopedSlots: {customRender: "actions"}
                    }
                ],
                actions: [
                    {
                        name: "new",
                        icon: "bi-plus-lg",
                        label: "btn.add"
                    },
                    {
                        name: "delete",
                        icon: "bi-trash",
                        label: "btn.delete"
                    }
                ],
                selectedRowKeys: [],
                form: {
                    title: '',
                    loading: false,
                    updateStatus: false,
                    selectedPackageItem: {},
                    data: {
                        just_without_usage: false
                    }
                },
            }
        }
    },
    computed: {
        products(){
            let products = [];

            this.$store.state.ecommerce.productManagement.product.table.data.forEach((product) => {
                if(product.type_id == 2){
                    product.translate = this.resolveDatum(product.translations, this.$root.defaultLanguage.id, 'language_id');
                    products.push(product);
                }
            })

            return products;
        },
        admins(){
            return this.$store.state.system.admin.table.data;
        },
        orderStatus() {
            return this.$store.state.ecommerce.order.status.table.data;
        },
        addablePackageItems(){
            let addablePackageItems = [];

            if(this.form.selectedProduct.package_items){
                let packageItems = this.form.data.package_items ? this.cloneData(this.form.data.package_items) : [];

                if(packageItems && packageItems.length){
                    packageItems = packageItems.map(packageItem => packageItem.product_id);
                }

                this.form.selectedProduct.package_items.forEach((packageItem) => {
                    if(!packageItems.includes(packageItem.product_package_id)){
                        addablePackageItems.push(packageItem);
                    }
                })
            }

            return addablePackageItems;
        }
    },
    mounted(){
        this.$store.dispatch('ecommerce/productManagement/product/get', { page: { pageSize: 1000 }});
        this.$store.dispatch('system/admin/get', {
            page: { pageSize: 9999 }
        });
        this.$store.dispatch('ecommerce/order/status/get', {
            page: { pageSize: 9999 }
        });
    },
    methods: {
        onSubmit() {
            this.$refs.changeUsageMultiForm.validate((valid) => {
                if (valid) {
                    this.form.loading = true;
                    let formData = this.prepareFormData();

                    this.axios.post(this.endpoints['ecommerce_order_item_change_usage_multi'], formData).then(response => {
                        this.onResponse(response.data, () => {
                            this.$router.push({
                                path: "/ecommerce/order-management/change-usage-multi"
                            });
                        }, () => {
                            this.form.loading = false;
                        });
                    }).catch(error => {
                        this.onResponseFailure(error.response.data, () => {
                            this.form.loading = false;
                        });
                    });
                } else {
                    return false;
                }
            });
        },
        selectedProduct(){
            if(this.form.data.product_id){
                this.axios.get(this.endpoints['ecommerce_product'] + '/' + this.form.data.product_id).then((response) => {
                    let data = response.data.data;
                    if(data.package_items && data.package_items.length){
                        let packageItems = [];
                        data.package_items.forEach((packageItem) => {
                            if(packageItem.product.type_id == 2){
                                packageItem.product.translate = this.resolveDatum(packageItem.product.translations, this.$root.defaultLanguage.id, 'language_id')
                                packageItems.push(packageItem);
                            }
                        });

                        data.package_items = packageItems;
                    }

                    this.form.selectedProduct = data;
                });
            }
        },
        changedStartProcessingNow(){
            if(this.form.data.start_processing_now){
                this.$confirm(
                    this.$t('pages.ecommerce.orderManagement.changeUsageMulti.save.startProcessingNowWarning'),
                    this.$t('messages.warning'),
                    {
                        confirmButtonText: this.$t('common.yes'),
                        cancelButtonText: this.$t('common.cancel'),
                        type: 'warning',
                    }
                )
                .then(() => {
                    this.form.data.start_processing_now = true;
                }).catch(() => {
                    this.form.data.start_processing_now = false;
                })
            }
        },
        disabledUsageMinDate(startDate) {
            startDate = this.$moment(startDate);
            const endDate = this.form.data.usage_max_date ? this.$moment(this.form.data.usage_max_date) : undefined;

            return endDate != undefined && startDate.valueOf() > endDate.valueOf();
        },
        disabledUsageMaxDate(endDate) {
            endDate = this.$moment(endDate);
            const startDate = this.form.data.usage_min_date ? this.$moment(this.$moment(this.form.data.usage_min_date).format('YYYY-MM-DD')) : undefined;

            return startDate == undefined ? false : startDate.valueOf() > endDate.valueOf();
        },
        prepareFormData() {
            let formData = this.cloneData(this.form.data);

            if(!(this.form.selectedProduct.group && this.form.selectedProduct.group.code == 'PACKAGE')){
                delete formData.package_items;
            }

            return formData;
        },
        newPackageItem(){
            this.packageItem.form.updateStatus = false;
            this.packageItem.form.data = {
                just_without_usage: false
            };
            this.packageItem.form.title = this.$t("pages.ecommerce.orderManagement.changeUsageMulti.save.packageItem.newPackageItem");
            this.showModal(this.$refs.packageItemModal);
        },
        editPackageItem(record){
            this.packageItem.form.title = this.$t("pages.ecommerce.orderManagement.changeUsageMulti.save.packageItem.editPackageItem");
            this.packageItem.form.updateStatus = true;
            this.packageItem.form.editIndex = undefined;

            let foundIndex = this.form.data.package_items.findIndex((packageItem) => packageItem == record);
            if(foundIndex != -1){
                this.packageItem.form.data = this.cloneData(this.form.data.package_items[foundIndex]);
                this.packageItem.form.editIndex = foundIndex;
                this.showModal(this.$refs.packageItemModal);
            }
        },
        selectedPackageItem(){
            if(this.packageItem.form.data.product_id && this.form.selectedProduct.package_items){
                let result = this.form.selectedProduct.package_items.filter((packageItem) => {
                    return packageItem.product_package_id == this.packageItem.form.data.product_id;
                });

                if(result.length == 1){
                    this.packageItem.form.selectedPackageItem = result[0];
                }
            }
        },
        onPackageItemSubmit(){
            this.$refs.packageItemForm.validate((valid) => {
                if (valid) {
                    if(!this.packageItem.form.updateStatus) {
                        let selectedPackageItem = this.packageItem.form.selectedPackageItem;
                        this.packageItem.form.data.name = selectedPackageItem.product.translate.title + ' (' +selectedPackageItem.product.group.name + ')';
                        this.form.data.package_items.push(this.packageItem.form.data);
                    } else if(this.packageItem.form.editIndex !== undefined){
                        this.form.data.package_items[this.packageItem.form.editIndex] = this.cloneData(this.packageItem.form.data);
                    }
                    this.hideModal(this.$refs.packageItemModal);
                } else {
                    return false;
                }
            });
        },
        handleOptionClickAction(name) {
            switch (name) {
                case "new":
                    this.newPackageItem();
                    break;

                case "delete":
                    this.deletePackageItemRecord(this.packageItem.selectedRowKeys.flat());
                    break;

                default:
                    break;
            }
        },
        handleOptionSelectedRow(record){
            this.packageItem.selectedRowKeys = record;
        },
        deletePackageItemRecord(record) {
            if (record.length == 0) {
                this.$message.warning(this.$t('messages.selectRow'))
                return;
            }

            let recordJson = JSON.stringify(record);

            this.form.data.package_items = this.form.data.package_items.filter(x => recordJson.indexOf(JSON.stringify(x)) == -1);
            this.packageItem.selectedRowKeys = this.packageItem.selectedRowKeys.filter(x => !recordJson.indexOf(JSON.stringify(x)) == -1);
        },
        disabledPackageItemUsageMinDate(startDate) {
            startDate = this.$moment(startDate);
            const endDate = this.packageItem.form.data.usage_max_date ? this.$moment(this.packageItem.form.data.usage_max_date) : undefined;

            return endDate != undefined && startDate.valueOf() > endDate.valueOf();
        },
        disabledPackageItemUsageMaxDate(endDate) {
            endDate = this.$moment(endDate);
            const startDate = this.packageItem.form.data.usage_min_date ? this.$moment(this.$moment(this.packageItem.form.data.usage_min_date).format('YYYY-MM-DD')) : undefined;

            return startDate == undefined ? false : startDate.valueOf() > endDate.valueOf();
        },
    }
}
</script>

<style>
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
    width: 100%;
}

.back-page-btn {
    height: 40px;
    line-height: 1.25;
}
</style>